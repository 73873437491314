<template>
    <div>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Name(s) -->
                    <b-col md="4">
                        <b-form-group
                            label="NOMBRE(S)"
                            label-for="names">
                            <b-form-input
                                id="names"
                                v-model="bookingData.client.names"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- First Surname -->
                    <b-col md="4">
                        <b-form-group
                            label="APELLIDO PATERNO"
                            label-for="first_surname">
                            <b-form-input
                                id="first_surname"
                                v-model="bookingData.client.first_surname"
                                disabled 
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Second Surname -->
                    <b-col md="4">
                        <b-form-group
                            label="APELLIDO MATERNO"
                            label-for="second_surname">
                            <b-form-input
                                id="second_surname"
                                v-model="bookingData.client.second_surname"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Document Number -->
                    <b-col md="4">
                        <b-form-group
                            label="NÚMERO DE DOCUMENTO"
                            label-for="document_number">
                            <b-form-input
                                id="document_number"
                                v-model="bookingData.client.document_number"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Email -->
                    <b-col md="4">
                        <b-form-group
                            label="CORREO ELECTRÓNICO"
                            label-for="email">
                            <b-form-input
                                id="email"
                                v-model="bookingData.client.email"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Phone -->
                    <b-col md="4">
                        <b-form-group
                            label="CELULAR"
                            label-for="phone">
                            <b-form-input
                                id="phone"
                                v-model="bookingData.client.phone"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Birthdate -->
                    <b-col md="3">
                        <b-form-group
                            label="FECHA DE NACIMIENTO"
                            label-for="birthdate">
                            <b-form-input
                                id="birthdate"
                                v-model="bookingData.client.birthdate"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Age -->
                    <b-col md="2">
                        <b-form-group
                            label="EDAD"
                            label-for="age">
                            <b-form-input
                                id="age"
                                v-model="bookingData.client.age"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Booking Day -->
                    <b-col md="3">
                        <b-form-group
                            label="SELECCIONAR DÍA"
                            label-for="day">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="fecha de la reserva"
                            >
                                <flat-pickr
                                    id="day"
                                    v-model="bookingData.day"
                                    class="form-control"
                                    :config="config"
                                    placeholder="YYYY-MM-DD"
                                    :state="(errors.length > 0 ? false:null)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Available Time -->
                    <b-col md="4">
                        <b-form-group
                            label="SELECCIONAR HORARIO DISPONIBLE"
                            label-for="available_time">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="horario disponible"
                            >
                                <v-select
                                    id="available_time"
                                    v-model="available_time"
                                    :state="errors.length > 0 ? false : null"
                                    :options="available_times"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Doctor -->
                    <b-col md="3">
                        <b-form-group
                            label="SELECCIONAR DOCTOR(A)"
                            label-for="doctor"
                        >
                            <v-select
                                id="doctor"
                                v-model="doctor"
                                :options="doctors"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Coupon -->
                    <b-col md="2">
                        <b-form-group
                            label="CUPÓN DE DESCUENTO"
                            label-for="coupon">
                            <b-form-input
                                id="coupon"
                                v-model="couponCode"
                                disabled
                                readonly
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Submit Button -->
                    <b-col cols="12">
                        <b-button
                            @click.prevent="validationForm"
                            variant="primary"
                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            type="submit"
                        >
                            ACTUALIZAR
                        </b-button>
                    </b-col>
                    
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { heightFade } from '@core/directives/animations';
     import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            flatPickr,
            vSelect,

            required
        },
        directives: {
            heightFade
        },
        props: {
            bookingData: {
                type: Object,
                required: true
            }
        },
        data (){
            return {
                available_times: [],
                doctors: [],
                available_time: null,
                doctor: null,
                couponCode: null,

                config: {
                    enableTime: false,
                    locale: Spanish,
                    //minDate: 'today'
                }
            }
        },
        async beforeMount (){
            await this.getAvailableTimes();
            await this.getDoctors();

            this.couponCode = this.bookingData.coupon ? this.bookingData.coupon.code : '---';
            this.available_time = this.available_times.find(i => i.value == this.bookingData.available_time_id);
            this.doctor = this.bookingData.doctor_id ? this.doctors.find(i => i.value == this.bookingData.doctor_id) : null;
        },
        methods: {
            async getAvailableTimes (){
                let response = await axios.get('/available-times/select');
                this.available_times = response.data;
            },
            async getDoctors (){
                let response = await axios.get('/doctors/select');
                this.doctors = response.data;
            },
            validationForm() {
                this.$refs.simpleRules.validate()
                    .then(success => {

                        if (success) {

                            let data = {
                                day: this.bookingData.day,
                                available_time_id: this.available_time.value,
                                doctor_id: this.doctor ? this.doctor.value : null
                            };

                            axios.put(`/booking/${this.bookingData.id}/update`, data)
                                .then(response => {

                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });

                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al crear el cupón de descuento.';
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                });
                            
                        }
                    });
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>