
<template>
    <component :is="bookingData === undefined ? 'div' : 'b-card'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="bookingData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos de la reserva</h4>
            <div class="alert-body">
                No se encontró ninguna reserva con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'bookings'}"
                >
                    Lista de reservas
                </b-link>
                para buscar otra reserva.
            </div>
        </b-alert>

        <b-tabs
            v-if="bookingData"
            pills
        >
      
            <!-- Tab: Information -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
         
                <booking-edit-tab-information
                    class="mt-2 pt-75"
                    :booking-data="bookingData"
                />
                
            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import BookingEditTabInformation from './BookingEditTabInformation.vue';
    import { ref, onUnmounted } from '@vue/composition-api';
    import bookingStoreModule from '../bookingStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            BookingEditTabInformation
        },
        setup() {
            const bookingData = ref(null);

            const USER_APP_STORE_MODULE_NAME = 'app-booking';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, bookingStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-booking/fetchBooking', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        bookingData.value = undefined
                    } else {
                        bookingData.value = response.data;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        bookingData.value = undefined
                    }
                });

            return {
                bookingData
            }
        }
    }

</script>